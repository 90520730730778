<template>
  <section>
    <div class="content-header">
      <h2>Multisystemic model of resilience </h2>
    </div>
    <div class="content-wrapper">
      <p>Dr. Michael Ungar, founder and Director of the Resilience Research Centre at Dalhousie University has developed a <strong class="bold">multisystemic model of resilience</strong>. By identifying 12 sources of resilience that contribute to an individual being able to bounce back after adversity, this model shows that resilience is not entirely an internal experience. </p>
      <p>Each of the factors is listed here. As a personal exploration, think about how you would fill in the blanks in the accompanying statements to determine how external factors contribute to resilience.</p>
      <div class="content-wrapper">
        <!-- <h3>CAROUSEL</h3> -->
        <div class="carousel">
          <hooper
            ref="carousel_01"
            class="mb-4"
            :wheelControl="false"
          >
            <slide>
              <div class="slide">
                <div>
                  <h4>Positive thinking</h4>
                  <ul>
                    <li>When bad things happen in my life, I say to myself, “__________” and I feel better.</li>
                    <li>I know I’m not to blame when __________ happens.</li>
                  </ul>
                  <p></p>
                </div>
              </div>
            </slide>

            <slide>
              <div class="slide">
                <div>
                  <h4>Structure</h4>
                  <ul>
                    <li>There are people in my life who expect me to __________.</li>
                  </ul>
                </div>
              </div>
            </slide>

            <slide>
              <div class="slide">
                <div>
                  <h4>Accountability</h4>
                  <ul>
                    <li>When I don’t meet expectations, I know that __________ will happen.</li>
                  </ul>
                </div>
              </div>
            </slide>

            <slide>
              <div class="slide">
                <div>
                  <h4>Intimate relationships</h4>
                  <ul>
                    <li>I can reach out to __________ to get help when I need it.</li>
                  </ul>
                </div>
              </div>
            </slide>

            <slide>
              <div class="slide">
                <div>
                  <h4>Supportive relationships</h4>
                  <ul>
                    <li>When bad things happen in my life, there are people like __________ who support me the best they can.</li>
                  </ul>
                </div>
              </div>
            </slide>

            <slide>
              <div class="slide">
                <div>
                  <h4>A powerful identity</h4>
                  <ul>
                    <li>I feel respected for what is special about me when I’m with, at or doing __________.</li>
                  </ul>
                </div>
              </div>
            </slide>
            <slide>
              <div class="slide">
                <div>
                  <h4>Sense of control</h4>
                  <ul>
                    <li>In my __________, I get to participate in making decisions that affect my __________.</li>
                  </ul>
                </div>
              </div>
            </slide>
            <slide>
              <div class="slide">
                <div>
                  <h4>Belonging culture:</h4>
                  <ul>
                    <li>At my __________, people miss me when I’m not there.</li>
                    <li>There are places I can go, such as __________, where I can celebrate my culture and beliefs.</li>
                  </ul>
                </div>
              </div>
            </slide>
            <slide>
              <div class="slide">
                <div>
                  <h4>Rights and responsibilities</h4>
                  <ul>
                    <li>When I’m with others at my __________, I feel treated fairly.</li>
                    <li>When I’m with __________, I’m responsible for myself and others.</li>
                  </ul>
                </div>
              </div>
            </slide>
            <slide>
              <div class="slide">
                <div>
                  <h4>Basic needs</h4>
                  <ul>
                    <li>I’m well cared for by __________.</li>
                    <li>I feel safe when I’m with or at __________.</li>
                  </ul>
                </div>
              </div>
            </slide>
            <slide>
              <div class="slide">
                <div>
                  <h4>Physical well-being</h4>
                  <ul>
                    <li>Physically, I’m able to __________ when I want to. </li>
                  </ul>
                </div>
              </div>
            </slide>
            <slide>
              <div class="slide">
                <div>
                  <h4>Financial well-being</h4>
                  <ul>
                    <li>Financially, I’m able to __________ when I want to.</li>
                  </ul>
                </div>
              </div>
            </slide>

            <hooper-navigation slot="hooper-addons">
              <img
                slot="hooper-prev"
                src="@/assets/img/_ui/svg/carousel-back.svg"
                alt="previous"
                srcset=""
              >
              <img
                slot="hooper-next"
                src="@/assets/img/_ui/svg/carousel-next.svg"
                alt="next"
                srcset=""
              >
            </hooper-navigation>

            <hooper-pagination slot="hooper-addons"></hooper-pagination>
          </hooper>
        </div>
      </div>
      <p class="lm">Learn more</p>
      <p>Michael Unger’s book, <em>Multisystemic Resilience</em>, brings together a wide range of resilience scholars who have been wrestling with how to explain processes of recovery, adaptation, and transformation in contexts of change and adversity. Every chapter provides a detailed review of systemic resilience from one disciplinary perspective, drawing from cutting edge research and case studies. </p>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination
} from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
